import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const BlogPost = ({ data }) => {
  const { contentfulBlog } = data
  const {
    title,
    description,
    author,
    image,
    childContentfulBlogContentRichTextNode,
  } = contentfulBlog

  const content = documentToReactComponents(
    childContentfulBlogContentRichTextNode.json
  )
  return (
    <Layout>
      <SEO title="Blog" />
      <section className="blog-heading">
        <div className="container">
          <div className="heading">
            <h1>{title}</h1>
            <h3>{description.description}</h3>
            <h5>{author}</h5>
          </div>
        </div>
      </section>

      <section className="blog-main">
        <div className="container">
          <div className="image">
            <Img fluid={image.fluid} />
          </div>
          <div className="text">{content}</div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulBlog(slug: { eq: $slug }) {
      id
      title
      slug
      author
      description {
        description
      }
      image {
        fluid {
          ...GatsbyContentfulFluid
        }
      }
      childContentfulBlogContentRichTextNode {
        json
      }
    }
  }
`

/* export const query = graphql`
  query($pathSlug: String!) {
    markdownRemark(frontmatter: { path: { eq: $pathSlug } }) {
      html
      frontmatter {
        title
        author
        html
        description
        image {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
` */

export default BlogPost
